<template>
  <v-app dark style="background-color: #efefef">
    <div>
      <v-img max-height="70" :src="headerPath">
        <div
          style="
            color: white;
            float: right;
            margin-top: 30px;
            margin-right: 2%;
            font-size: 1.23rem;
            text-shadow: 0 0 5px #000;
          "
        >
          App. No. : {{ $store.state.eventInfo.AppIdDisplay }}
        </div>
      </v-img>
      <v-toolbar dense flat>
        <v-card
          color="transparent"
          flat
          style="margin-top: -70px; border-radius: 0px"
        >
          <img :src="logoPath" style="cursor: pointer" width="170px" />
        </v-card>
        <v-toolbar-title class="ml-4"
          ><h4>{{ greeting }}, {{ $store.state.eventInfo.UserName }}</h4>
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn outlined color="primary" @click="logout" class="ml-3"
          ><v-icon left>mdi-logout</v-icon> Logout</v-btn
        >
      </v-toolbar>
    </div>
    <v-main>
      <div class="mb-10">
        <router-view></router-view>
      </div>
    </v-main>
    <v-footer dark class="pt-1 pb-1">
      <v-card width="100%">
        <v-card-actions class="pa-1" style="font-size: 0.8rem">
          <span>&copy; {{ new Date().getFullYear() }}</span>
          <v-spacer></v-spacer>
          Powered By
          <img src="/DNA-Logo-mono.png" height="45px" class="pl-3" alt="" />
        </v-card-actions>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  props: { EventUrl: String,VenueUrl: String },
  data() {
    return {
      logoPath: "",
      headerPath: "",
      clipped: false,
      drawer: true,
      fixed: false,
      cpDialog: false,
      greeting: "",
    };
  },
  async mounted() {
    // await this.$store.commit("setEventUrl", this.EventUrl);
    // await this.$store.commit("setVenueUrl", this.VenueUrl);
    if (this.$store.state.eventInfo.AppId == "") {
      this.$router
        .push("/" + this.$store.state.eventUrl + "/" + this.$store.state.venueUrl + "/login")
        .catch(() => {});
    }
    this.logoPath =
      this.$apiPhotoPath + "/assets/" + this.EventUrl + "/logo.jpg";

    this.headerPath =
      this.$apiPhotoPath + "/assets/" + this.EventUrl + "/header.jpg";
    this.greet();
  },
  methods: {
    greet() {
      const time = new Date().getHours();
      if (time < 12) {
        this.greeting = "Good Morning";
      } else if (time < 17) {
        this.greeting = "Good Afternoon";
      } else {
        this.greeting = "Good Evening";
      }
    },
    logout: function () {
      this.$store.commit("logout");
      this.$router
        .push("/" + this.$store.state.eventUrl + "/" + this.$store.state.venueUrl + "/login")
        .catch(() => {});
    },
  },
};
</script>
<style>
/* body::before {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;DAX
    left: 0;
    background: linear-gradient(#f48d95, #fed387);
} */
.cardHeader {
  background-color: rgb(226, 240, 252);
  padding: 8px;
  padding-left: 10px;
  border-left: 10px solid rgb(75, 35, 250);
  font-weight: 500;
  font-size: 1.05rem;
}
</style>